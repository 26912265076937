const colors = {
  red: '#cd1b1f',
  rejectRed: '#fd3c4f',
  errorLight: '#FFF6F6',
  yellow: '#fed400',
  secondary: '#fdd450',
  brown: '#51302e',
  white: '#fff',
  veryLightGray: '#f8f8f8',
  lightGray: '#f1f1f1',
  mediumLightGray: '#ececec',
  mediumLightGray2: '#e6e6e6',
  gray: '#b3b3b3',
  mediumGray: '#868686',
  mediumDarkGray: '#474747',
  darkGray: '#363636',
  lightBlue: '#90dceb',
  primary: '#00a9ce',
  primaryDarker: '#17a3cb',
  primaryHover: '#127e9d',
  vivigoPrimary: '#52D800',
  vivigoPrimaryDarker: '#52D800',
  vivusSecondary: '#006d38',
  inputColor: '#474747',
  inputBorder: '#d3d3d3',
};

export default colors;
