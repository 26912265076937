export default ({ colors, mediaQueries }) => ({
  root: {
    width: '390px',
    [mediaQueries.breakpointLaptopSmall]: {
      width: '398px',
      marginTop: '12px',
    },
    [mediaQueries.breakpointSmall]: {
      width: '100%',
    },
  },

  button: {
    backgroundColor: colors.vivigoPrimary,
    '&:hover': {
      backgroundColor: colors.vivigoPrimaryDarker,
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        height: '32px',
        transform: 'scale(1.4) translateX(-8px)',
        marginRight: '8px',
      },
    },
  },
  legal: {
    width: '100%',
    textAlign: 'center',
    padding: '0 16px',
    marginTop: '12px',
    fontSize: '11px',
  },
});
