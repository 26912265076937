import { compose } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AppStyles from '../../lib/styles/Styles';
import config from '4finance-configuration-pl';
import Cookiebot from './Cookiebot';
import Helmet from 'react-helmet';
import MetaTag from '../components/MetaTag';
import metaTags from '../../../localizations/partials/metaTags.json';
import ScrollToTop from './ScrollToTop';

type Props = {
  children?: any;
  location: Location;
};

const App: React.FC<Props & RouteComponentProps> = (props: Props) => {
  const {
    children,
    location: { pathname },
  } = props;
  const className = pathname.replace(/^.*?([a-z0-9-]+).*$/, '$1').replace('/', 'home');
  const metaTag = metaTags.find(tag => tag.fields.url === pathname)?.fields || {};

  return (
    <>
      <AppStyles />
      <ScrollToTop />

      <Helmet>
        <script src="/toolkit.js" type="text/javascript" />
        {config.cookiebotCBID
          ? Cookiebot(config.cookiebotCBID)
          : (
            <script
              id="CookieConsent"
              src="https://policy.app.cookieinformation.com/uc.js"
              data-culture="PL"
              type="text/javascript"
            />
          )}
      </Helmet>

      <div className={`page ${className}`} data-pathname={pathname}>
        {metaTag && (
          <MetaTag
            metaTags={metaTag}
            link={[
              {
                rel: 'canonical',
                href: `https://www.vivigo.pl${pathname}`,
              },
            ]}
            pathname={pathname}
          />
        )}
        {children}
      </div>
    </>
  );
};

export default compose<any>(withRouter)(App);
