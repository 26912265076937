export default function Cookiebot(cookiebotCBID: string) {
  return [
    <script key="gtag" data-cookieconsent="ignore">
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("consent", "default", {
          ad_personalization: "denied",
          ad_storage: "denied",
          ad_user_data: "denied",
          analytics_storage: "denied",
          functionality_storage: "denied",
          personalization_storage: "denied",
          security_storage: "granted",
          wait_for_update: 1000,
        });
        gtag("set", "ads_data_redaction", true);
        gtag("set", "url_passthrough", true);
      `}
    </script>,
    <script
      key="cookiebot"
      id="Cookiebot"
      src="https://consent.cookiebot.com/uc.js"
      data-cbid={cookiebotCBID}
      data-blockingmode="auto"
      type="text/javascript"
    />,
  ];
}
