import * as DashboardSection from '@dynamic/components/DashboardSection';
import * as R from '@routes/routesList';

import { BodyText, SecondaryHeading } from '@shared/components/typography';
import { compose } from 'redux';
import { connectActions, connectPrefetch, connectState } from '@browser/connect';
import { DynamicCalculator } from '@browser/calculator';
import { memo, useCallback } from 'react';
import { pick } from 'lodash';
import { RouteAction } from '@common/types';
import { useTranslator } from '@localizations';
import config from '4finance-configuration-pl';
import DashboardBlockedForeigner from '@dynamic/dashboard/components/DashboardBlockedForeigner';
import injectStyles from '4finance-components-pl';
import Proposal from './Proposal';
import Separator from '@shared/components/Separator';
import styles from './NoActiveLoan.jss';
import type { ImmutableMap } from '@common/lib/types';

const LOANS_DISABLED = config.loans?.disabled;

type Props = {
  classes: Record<string, any>;
  proposal: ImmutableMap | null | undefined;
  validationResolutionDetail: string;
  foreigner?: boolean;
  updatePath: RouteAction;
  bankAccountNumber: string;
};

function getTextContent(proposal: any, msg: (...args: Array<any>) => any): [string, string] {
  // eslint-disable-next-line no-nested-ternary
  const group = LOANS_DISABLED ? 'disabled' : proposal ? 'proposal' : 'default';
  // Used translations:
  // dashboard.loan.no_active.default.heading, dashboard.loan.no_active.default.body
  // dashboard.loan.no_active.proposal.heading, dashboard.loan.no_active.proposal.body
  // dashboard.loan.no_active.disabled.heading, dashboard.loan.no_active.disabled.body

  return [msg(`dashboard.loan.no_active.${group}.heading`), msg(`dashboard.loan.no_active.${group}.body`)];
}

const NoActiveLoan = (props: Props): JSX.Element => {
  const {
    classes,
    proposal,
    validationResolutionDetail,
    foreigner,
    updatePath,
    bankAccountNumber,
  } = props;
  const {
    msg,
  } = useTranslator();

  const calculatorClasses = {
    container: classes.calculatorContainer,
    ...pick(classes, ['termContainer', 'offerContainer', 'sliderContainer']),
  };
  const [mainTitle, bodyText] = getTextContent(proposal, msg);

  if (foreigner) {
    return <DashboardBlockedForeigner mainTitle={mainTitle} bodyText={bodyText} />;
  }

  const onApply = useCallback(() => {
    updatePath(!bankAccountNumber ? R.PROFILE : R.APPLICATION_CONFIRM_BANK_ACCOUNT);
  }, [updatePath]);

  return (
    <>
      <DashboardSection.Block className={classes.leftPanel}>
        <div className={classes.backgroundWrapper}>
          <SecondaryHeading bigger brown lighter>
            {mainTitle}
          </SecondaryHeading>
          <Separator className={classes.separator} />
          <BodyText html brown>
            {bodyText}
          </BodyText>
        </div>
      </DashboardSection.Block>
      <DashboardSection.Block yellow>
        {validationResolutionDetail !== 'APPLICATIONS_DISABLED' ? (
          <>
            <SecondaryHeading bigger brown className={classes.detailsHeading}>
              {msg('dashboard.loan.details')}
            </SecondaryHeading>
            {!proposal ?
              (
                <DynamicCalculator classes={calculatorClasses} onApply={onApply} />
              )
              : <Proposal />}
          </>
        )
          : (
            <div className={classes.blocked}>
              <SecondaryHeading bigger brown lighter>
                Zaplo.pl jest w trakcie prac modernizacyjnych uniemożliwiających wnioskowanie o pożyczki. Przepraszamy!!!
              </SecondaryHeading>
              <p>Zajrzyj do nas wkrótce</p>
            </div>
          )}
      </DashboardSection.Block>
    </>
  );
};

NoActiveLoan.styleRoot = 'NoActiveLoan';
export default memo(
  compose<any>(
    connectPrefetch([['api', 'fetchClientApplication']]),
    connectActions({
      updatePath: ['router', 'updatePath'],
    }),
    connectState({
      bankAccountNumber: ['api', 'fetch', 'client', 'bankAccountNumber', 'query', 'data', 'bankAccountNumber'],
      proposal: ['api', 'fetch', 'client', 'application', 'query', 'data', 'proposal'],
      foreigner: ['api', 'fetch', 'client', 'query', 'data', 'foreigner'],
      validationResolutionDetail: ['api', 'fetch', 'client', 'query', 'data', 'validationResolutionDetail'],
    }),
    injectStyles(styles),
  )(NoActiveLoan),
);
