export default ({ colors }: { colors: Record<string, any> }) => ({
  outerContainer: {
    width: '570px',
    backgroundColor: '#F9F7F2',
    margin: '-32px 0 -32px -32px',
  },
  innerContainer: {
    padding: '32px',
  },
  logo: {
    height: '45px',
    width: '270px',
    backgroundRepeat: 'no-repeat',
    marginBottom: '20px',
  },
  line1: {
    color: colors.vivigoPrimary,
    fontSize: '30px',
    fontWeight: '700',
    letterSpacing: '1px',
    width: '320px',
  },
  line2: {
    color: colors.vivigoSecondary,
    fontSize: '52px',
    fontWeight: '700',
    width: '320px',
    letterSpacing: '1px',
  },
  button: {
    backgroundColor: `${colors.vivigoSecondary} !important`,
    width: '320px',
    display: 'block',
  },
  disclaimer: {
    color: colors.vivigoPrimary,
    textAlign: 'center',
    margin: '16px 0',
  },
});
